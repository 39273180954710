<!-- eslint-disable -->
<template>
  <div class="policy">
    <div class="content f-w3">
<!--      <h4>PreGoの利用に関するプライバシーポリシー</h4>-->
      <p>
        この「ＰｒｅＧｏ（プレゴ）の利用に関するプライバシーポリシー」（以下、「本ポリシー」といいます。）は、株式会社エフオーピーシー（以下、「当社」といいます。）が、当社の「プレゴ」により、お客様（以下、「ご本人」又は「利用者」といいます。）にご提供する各種サービス（以下、「本サービス」といいます。）において取り扱う個人情報（個人情報保護法に定める個人情報をいいます）を含む利用者情報の取扱いについて定めるものです。なお、本ポリシーと当社が別途定める他の規定の内容が矛盾する場合、本サービスに係る利用者情報の取扱いについては、本ポリシーの内容が優先するものとします。<br />
      </p>
      <p class="conditions">第１条（収集する利用者情報及び収集方法）</p>
      <p>
        本ポリシーにおいて、「利用者情報」とは、利用者の識別に係る情報、通信サービス上の行動履歴、その他利用者または利用者の端末機器に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものをいいます。本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、本ポリシー制定時点（その後、変更された場合にはその内容に変更されます）で以下のようなものとなります。<br />
        (1)利用者からご提供いただく情報<br />
        本サービスを利用するために、または本サービスの利用を通じて利用者からご提供いただく情報は以下のとおりです。<br />
        ・氏名、生年月日、性別、職業などプロフィールに関する情報<br />
        ・メールアドレス、ＳＭＳ確認用電話番号、住所など連絡先に関する情報<br />
        ・クレジットカード情報など決済手段に関する情報<br />
        ・銀行口座番号<br />
        ・運転免許証番号<br />
        ・利用者の肖像を含む静止画<br />
        ・動画情報<br />
        ・入力フォームその他当社が定める方法を通じて利用者が入力または送信する情報<br />
        ・位置情報および移動履歴<br />
        ・プレゴの利用内容及び利用履歴<br />
        (2)利用者が本サービスを利用するにあたって、当社が収集する情報<br />
        当社は、本サービスのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。<br />
        ・リファラ<br />
        ・ＩＰアドレス<br />
        ・サーバーアクセスログに関する情報<br />
        ・Ｃｏｏｋｉｅ、ＡＤＩＤ、ＩＤＦＡその他の識別子<br />
        ・端末情報<br />
        ・位置情報<br />
      </p>
      <p class="conditions">第２条（利用者情報の取扱いについて）</p>
      <p>
        当社が本サービスにおいて取り扱う利用者情報の利用目的は、次のとおりです。<br />
        １、本サービスに関する登録の受付、本人確認、利用料金の計算等、本サービスの提供、維持、保護及び改善のため<br />
        ２、本サービスに関する利用料金を利用者に請求するため<br />
        ３、次の各場合において通知を行うため<br />
        a.本サービスに関して、キャンペーン、お知らせ、各種調査（アンケート調査など）又はサービス内容に係るご案内<br />
        b.利用者が本サービスにおいて設定した各種通知の送信<br />
        ４、本サービスに関するご案内、お問い合わせ等への対応、ご連絡、お知らせのため<br />
        ５、前号に関する調査の実施のため<br />
        ６、本サービスに関する当社の規約、ポリシー等（以下、「規約等」といいます。）に違反する行為に対する対応のため<br />
        ７、本サービス以外の他のサービス（提携先が提供するものを含む）を利用者に提供、告知するため<br />
        ８、利用者に自身の登録情報の修正、閲覧をしていただくため<br />
        ９、本サービスの改良、新サービスの開発のための調査の実施のため<br />
        １０、本サービスに関する規約等の変更等を通知するため<br />
        １１、本サービスの内容変更の通知をするため<br />
        １２、本サービスの停止、中止、終了、解約、精算の通知をするため<br />
        １３、行政、司法その他の公的機関からの情報提供要請に回答するため<br />
        １４、前各号の目的に付随する利用目的のため<br />
      </p>
      <p class="conditions">第３条（保有個人データに関する事項の周知）</p>
      <p>
        本サービスに関して当社で保有している保有個人データ（個人情報法護法第１６条第４項で定義されたものをいいます）について、個人情報保護法により公表等が求められる事項について、次のとおり、公表いたします。当該保有個人データに関し、ご本人様又はその代理人様からの①利用目的の通知、②保有個人データ又は第三者提供記録の開示、③内容の訂正、追加又は削除、④法令違反の取扱や取得に係る利用の停止又は消去、⑤法令違反の提供に係る第三者への提供の停止、⑥当社が利用する必要がなくなった場合や権利侵害等のおそれがある場合に係る利用の停止又は消去もしくは第三者への提供の停止、の請求（以下、併せて「開示等のご請求」といいます）につきましては、以下の第３号及び第５号に記載しております。<br />
        (1)個人情報取扱事業者の名称、住所及び代表者の氏名<br />
        事業者の名称：株式会社エフオーピーシー<br />
        住所：東京都渋谷区千駄ヶ谷四丁目２９番地１２北参道ダイヤモンドパレス５０８<br />
        代表者の氏名：森修平<br />
        (2)全ての保有個人データの利用目的<br />
        本ポリシー第２条に記載のとおりとなります。<br />
        (3)保有個人データの取扱いに関する問合せ先<br />
        プレゴお問い合わせフォーム<br />
        URL：<span class="link" @click="showForm()">
          https://forms.gle/6ScyRbBU9iJuAQwr8
        </span> <br />
        (4)認定個人情報保護団体<br />
        現在、当社が加盟する認定個人情報保護団体はありません。<br />
        (5)保有個人データの開示等のご請求に応じる手続<br />
        a.開示等のご請求の申し出先開示等のご請求は、上記フォームにお申し出ください。<br />
        b.開示等のご請求に関するお手続き<br />
        ・お申し出受付け後、当社から当社所定の請求書様式「保有個人データ開示等請求書」を郵送又は電子メールにより送付いたします。<br />
        ・ご記入いただいた請求書、代理人によるご請求の場合は代理人であることを確認する書類、手数料分の郵便為替（利用目的の通知並びに開示の請求の場合のみ）を上記(1)記載の住所宛で当社にご郵送ください（なりすまし防止等のため電子メールによる送信は受け付けておりません）。<br />
        ・上記請求書を受領後、ご本人確認のため、当社に登録していただいている個人情報のうち数項目の情報をお問合せさせていただきます。<br />
        ・回答は原則としてご本人に対してご本人指定の方法（本人宛の書面送付もしくは電磁的記録の提供）にて行います。電磁的記録の提供の場合は、当社指定の方法（ＰＤＦでメール送信）によって提供します。<br />
        c.代理人によるご請求の場合、代理人であることを確認する資料開示等をご請求になる方が代理人様である場合は、代理人である事を証明する資料及び代理人様ご自身を証明する資料を同封してください。各資料に含まれる本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください。また各資料は個人番号を含まないものをお送りいただくか、全桁を墨塗り等の処理をしてください。<br />
        (6)保有個人データの訂正・削除、利用停止等のご請求に応じる手続<br />
        a.訂正・削除、利用停止等のご請求は、上記フォームにお申し出ください。<br />
        b.訂正・削除、利用停止等のご請求に関するお手続き<br />
        ・お申し出受付け後、当社から当社所定の請求書様式「保有個人データ開示等請求書」を郵送又は電子メールにより送付いたします。<br />
        ・ご記入いただいた請求書、代理人によるご請求の場合は代理人であることを確認する書類、手数料分の郵便為替（利用目的の通知並びに訂正・削除、利用停止等の請求の場合のみ）を上記(1)記載の住所宛で当社にご郵送ください（なりすまし防止等のため電子メールによる送信は受け付けておりません）。<br />
        ・上記請求書を受領後、ご本人確認のため、当社に登録していただいている個人情報のうち数項目の情報をお問合せさせていただきます。<br />
        ・回答は原則としてご本人に対してご本人指定の方法（本人宛の書面送付もしくは電磁的記録の提供）にて行います。電磁的記録の提供の場合は、当社指定の方法（ＰＤＦでメール送信）によって提供します。<br />
        c.代理人によるご請求の場合、代理人であることを確認する資料<br />
        訂正・削除、利用停止等をご請求になる方が代理人様である場合は、代理人である事を証明する資料及び代理人様ご自身を証明する資料を同封してください。各資料に含まれる本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください。また各資料は個人番号を含まないものをお送りいただくか、全桁を墨塗り等の処理をしてください。<br />
        <br />
        【代理人である事を証明する資料】<br />
        ＜開示等のご請求をすることにつき本人が委任した代理人様の場合＞<br />
        本人の委任状（原本）<br />
        ＜代理人様が未成年者の法定代理人の場合＞いずれかの写し<br />
        戸籍謄本<br />
        住民票（続柄の記載されたもの）<br />
        その他法定代理権の確認ができる公的書類<br />
        ＜代理人様が成年被後見人の法定代理人の場合＞いずれかの写し<br />
        後見登記等に関する登記事項証明書<br />
        その他法定代理権の確認ができる公的書類<br />
        <br />
        【代理人様ご自身を証明する資料】いずれかの写し<br />
        運転免許証<br />
        パスポート<br />
        健康保険の被保険者証<br />
        住民票（個人番号カードの記載のないもの）<br />
        個人番号カード（表面）<br />
        d.利用目的の通知又は保有個人データの開示のご請求についての手数料1回のお求めにつき1,000円（お送りいただく請求書等に郵便為替を同封していただきます）<br />
        e.開示等のご請求に対し、個人情報保護法その他の法令により、当社がこれに応じる義務を負わない場合にはご請求に応じかねる場合があります。<br />
        f.なお、利用者情報の項目によっては、その収集、利用または第三者提供が本サービスの前提となる場合があるため、その場合には当社所定の方法により本サービスを終了した後に遅滞なく当社はその収集、利用または第三者提供を停止します。<br />
      </p>
      <p class="conditions">第４条（第三者提供等）</p>
      <p>
        当社は、本サービスの提供又は管理その他本サービスの目的を達成するために必要な範囲で、クラウドサーバーの管理会社、位置情報の提供会社、システムメンテナンス会社などの第三者に利用者情報の全部又は一部を提供し、又は共同利用します。<br />
      </p>
      <p class="conditions">第５条（本ポリシーの変更手続き）</p>
      <p>
        当社は、必要に応じて、本ポリシーを変更します。但し、法令上利用者の同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意した利用者のみに対して適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、または利用者に通知します。<br />
      </p>
      <p class="conditions">第６条（附則）</p>
      <p>
        ２０２２年４月３０日 制定・施行<br />
        ２０２３年３月１０日 改訂<br />
        ２０２３年４月１３日 改訂
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyCommon",
  methods: {
    showForm() {
      window.open("https://forms.gle/6ScyRbBU9iJuAQwr8");
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_fontFamily.scss";
.policy {
  margin: auto;
  text-align: left;
  font-size: 16px;
  padding: 0 19px 64px 19px;

  .content {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    color: #292929;
    padding-top: 20px;
    &.text {
      font-size: 14px;
    }

    h4 {
      font-weight: 600;
      margin-bottom: 25px;
    }

    .conditions {
      font-weight: 600;
    }

    .link {
      color: #0cc8f2;
      cursor: pointer;
    }
  }
}
@media screen and (min-width: 1200px) {
  .policy {
    .content {
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
      color: #292929;
      padding: 41px 245px 0 245px;
      font-size: 16px;
      &.text {
        font-size: 23px;
      }
    }
  }
}
</style>
